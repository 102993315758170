/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
// core components
import styles from '../../assets/jss/material-dashboard-react/components/footerStyle.js';
import { Box } from '@mui/material';
import LogoFooter from '../../assets/img/logo_footer.png';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className="w-full">
      <Box className="w-full">
        <Box className="container flex flex-col min-h-[240px] py-[40px]">
          <Box className="flex flex-wrap gap-10 items-center">
            <Box>
              <img src={LogoFooter} />
            </Box>
            <a href="https://t.me/eissaei_dev" target='_blank' className="mr-auto font-extrabold text-white px-[35px] py-[13px] rounded-[10px] border bg-[#DCB36F] border-[#DCB36F] hover:text-white focus:text-white">Verify</a>
            <Box className="flex gap-[25px] items-center flex-wrap">
              <p className="text-[16px]">© {new Date().getFullYear()} RZChain</p>
              <a href="#" target="_blank">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="17" cy="17" r="17" fill="#DCB36F" />
                  <path
                    d="M7.33348 7.28577L14.8354 18.0051L7.28564 26.7143H8.98375L15.5928 19.0869L20.9343 26.7143H26.7142L18.7897 15.3945L25.8173 7.28577H24.1192L18.0323 14.3085L13.1134 7.28577H7.33348ZM9.8328 8.62302H12.4876L24.2149 25.3771H21.5601L9.8328 8.62302Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a href="#" target="_blank">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="17" cy="17" r="17" fill="#DCB36F" />
                  <path
                    d="M5.29351 16.6566L10.3868 18.5843L12.2787 24.9604C12.4242 25.4052 12.8608 25.5535 13.1518 25.257L15.9168 22.8844C16.2078 22.5879 16.6444 22.5879 16.9354 22.8844L22.0288 26.5915C22.3198 26.8881 22.9019 26.5915 22.9019 26.2949L26.6855 8.05626C26.8311 7.61142 26.3945 7.16657 25.9579 7.31485L5.29351 15.4704C4.71141 15.6186 4.71141 16.36 5.29351 16.6566ZM11.9876 17.5463L21.8832 11.3185C22.0288 11.1702 22.1743 11.4667 22.0288 11.615L13.8794 19.3257C13.5884 19.6222 13.4428 19.9188 13.2973 20.3637L13.0063 22.4396C13.0063 22.7362 12.5697 22.7362 12.5697 22.4396L11.551 18.5843C11.4055 18.2877 11.551 17.6946 11.9876 17.5463Z"
                    fill="black"
                  />
                </svg>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box className="w-full border-t border-t-[#2C2C2C] py-[30px]">
        <Box className="container flex flex-wrap gap-10">
          <p className="text-[17px] mr-auto order-2 md:order-1">
            © {new Date().getFullYear()} by RZCOIN. All right reserved
          </p>
          <Box className="flex flex-wrap gap-10 order-1 md:order-2">
            <a href="mailto:contact@rz.game" className="text-[17px]" target="_blank">
              Careers
            </a>
            <a href="https://rz.game/legal/" className="text-[17px]" target="_blank">
              Legal
            </a>
            <a href="mailto:contact@rz.game" className="text-[17px]" target="_blank">
              Contact
            </a>
          </Box>
        </Box>
      </Box> */}
    </footer>
  );
}
