import { StandardTokenABI } from './ABI/StandardToken/Token.js';
import { TokenLockABI } from './ABI/TokenLock/tokenlock.js';
import { LptokenLockABI } from './ABI/LiquidityLock/lptokenlocker.js';
import { LptokenABI } from './ABI/LiquidityLock/lptoken.js';
import { PresaleManageABI } from './ABI/Presale/PresaleManage.js';
import { PresaleSettingABI } from './ABI/Presale/PresaleSetting.js';
import { PresaleABI } from './ABI/Presale/Presale.js';
// import {PresaleABI} from "./ABI/Presale/Presale_Temp.js";
import { LockForwarderABI } from './ABI/Presale/PresaleLockForwarder.js';
import { manageCreateTokenABI } from './ABI/CreateToken/CreateManage.js';
import { standardTokenCreateABI } from './ABI/CreateToken/StandardToken.js';
import { liquidityTokenCreateABI } from './ABI/CreateToken/LiquidityToken.js';

export const TOKENLOCK_ADDRESS = {
  OnchainAI: '0x0000000000000000000000000000000000000000',
};

export const LIQUIDITYLOCK_ADDRESS = {
  OnchainAI: '0x0000000000000000000000000000000000000000',
};

export const PRESALE_MANAGE_ADDRESS = {
  OnchainAI: '0x0000000000000000000000000000000000000000',
};

export const CREATE_TOKEN_MANAGE_ADDRESS = {
  OnchainAI: '0x0952Ef610B29282147428b781b437Acd7F84332f',
};

////////////////////////////////////////////////////////////////////////////////////////////

export const STANDARD_TOKEN_ABI = StandardTokenABI;

export const TOKEN_LOCK_ABI = TokenLockABI;

export const LIQUIDITY_LOCK_ABI = {
  lock: LptokenLockABI,
  token: LptokenABI,
};

export const PRESALE_ABI = {
  manage: PresaleManageABI,
  setting: PresaleSettingABI,
  presale: PresaleABI,
  lockforwarder: LockForwarderABI,
};

export const CREATE_TOKEN_ABI = {
  manage: manageCreateTokenABI,
  standard: standardTokenCreateABI,
  liquidity: liquidityTokenCreateABI,
};

export const GetCoinPriceURL = {
  OnchainAI: 'https://min-api.cryptocompare.com/data/price?fsym=BOBA&tsyms=BTC,USD,EUR',
};

export const CRYPTCOMPARE_API_KEY = '2b0dfed1b47571bdae1735c9b393014f03678d41a249248c24fc0e400b0854f9';
